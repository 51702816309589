.panel {
  border: 0;
  border-bottom: 1px solid $medium-gray;
  box-shadow: none;
}
.panel-default > .panel-heading {
  background-color: $white-color;
  border-color: $white-color;
}
.panel-group .panel {
  border-radius: 0;
}
.panel-title {
  font-size: $font-size-h5;
}
.panel-title a:hover,
.panel-title a:focus {
  text-decoration: none;
}
.collapse-hover {
  display: block;
  height: 0px;
  visibility: visible;
  overflow: hidden;
}
.panel-title a:hover,
.panel-title a:focus {
  color: $default-states-color;
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
  box-shadow: inset 0 7px 10px -7px rgba(0, 0, 0, 0.14);
}
.panel-heading {
  padding: 0;

  .caret {
    float: right;
    margin-top: 12px;
    margin-right: $padding-default-horizontal;
  }

  a {
    padding: $padding-default-vertical 0;
    display: block;
    width: 100%;

    .content-full-width & {
      padding: $padding-default-vertical $padding-default-horizontal;
    }
  }
}
