// SweetAlert
// github.com/limonte/sweetalert2

.sweet-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 1000;
}

.sweet-alert {
  background-color: #fff;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-top: -200px;
  max-height: 90%;
  overflow-x: hidden;
  overflow-y: auto;
  display: none;
  z-index: 2000;

  &:focus {
    outline: 0;
  }

  button {
    margin: 0 5px;
    padding: 8px 16px;
  }

  .form-control {
    max-width: 300px;
    margin: 0 auto;
  }
}
.sweet-alert h2 {
  color: #575757;
  text-align: center;
  text-transform: none;
  position: relative;
  padding: 0;
  display: block;
}
.sweet-alert p {
  text-align: center;
  position: relative;
  float: none;
  margin: 0;
  padding: 0;
}
.sweet-alert hr {
  height: 5px;
  color: transparent;
  border: 0;
}
.sweet-alert button.styled {
  color: #fff;
  border: 0;
  box-shadow: none;
  font-size: 17px;
  font-weight: 500;
  border-radius: 5px;
  padding: 10px 32px;
  margin: 0 5px;
  cursor: pointer;
}
.sweet-alert button.styled:focus {
  outline: none;
}
.sweet-alert button.styled.sweet-cancel[disabled] {
  opacity: 0.4;
}
.sweet-alert button.styled.sweet-confirm[disabled] {
  border: 4px solid transparent;
  border-color: transparent;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0 30px;
  vertical-align: middle;
  background-color: transparent !important;
  color: transparent;
  cursor: default;
  border-radius: 100%;
  -webkit-animation: rotate-loading 1.5s linear 0s infinite normal;
  animation: rotate-loading 1.5s linear 0s infinite normal;
}
.sweet-alert button.styled::-moz-focus-inner {
  border: 0;
}
.sweet-alert[data-has-cancel-button="false"] button {
  box-shadow: none !important;
}
.sweet-alert .icon {
  width: 80px;
  height: 80px;
  border: 4px solid #808080;
  border-radius: 50%;
  margin: 20px auto;
  padding: 0;
  position: relative;
  box-sizing: content-box;
}
.sweet-alert .icon.error {
  border-color: #f27474;
}
.sweet-alert .icon.error .x-mark {
  position: relative;
  display: block;
}
.sweet-alert .icon.error .line {
  position: absolute;
  height: 5px;
  width: 47px;
  background-color: $color-red;
  display: block;
  top: 37px;
  border-radius: 2px;
}
.sweet-alert .icon.error .line.left {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 17px;
}
.sweet-alert .icon.error .line.right {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  right: 16px;
}
.sweet-alert .icon.warning {
  border-color: #f8bb86;
}
.sweet-alert .icon.warning .body {
  position: absolute;
  width: 5px;
  height: 47px;
  left: 50%;
  top: 10px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: #f8bb86;
}
.sweet-alert .icon.warning .dot {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: -3px;
  left: 50%;
  bottom: 10px;
  background-color: #f8bb86;
}
.sweet-alert .icon.info {
  border-color: #c9dae1;
}
.sweet-alert .icon.info::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 29px;
  left: 50%;
  bottom: 17px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: #c9dae1;
}
.sweet-alert .icon.info::after {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: -3px;
  top: 19px;
  background-color: #c9dae1;
}
.sweet-alert .icon.success {
  border-color: #a5dc86;
}
.sweet-alert .icon.success::before,
.sweet-alert .icon.success::after {
  content: "";
  border-radius: 50%;
  position: absolute;
  width: 60px;
  height: 120px;
  background: #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.sweet-alert .icon.success::before {
  border-radius: 120px 0 0 120px;
  top: -7px;
  left: -33px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 60px 60px;
  transform-origin: 60px 60px;
}
.sweet-alert .icon.success::after {
  border-radius: 0 120px 120px 0;
  top: -11px;
  left: 30px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 60px;
  transform-origin: 0 60px;
}
.sweet-alert .icon.success .placeholder {
  width: 80px;
  height: 80px;
  border: 4px solid rgba(165, 220, 134, 0.2);
  border-radius: 50%;
  box-sizing: content-box;
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 2;
}
.sweet-alert .icon.success .fix {
  width: 5px;
  height: 90px;
  background-color: #fff;
  position: absolute;
  left: 28px;
  top: 8px;
  z-index: 1;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.sweet-alert .icon.success .line {
  height: 5px;
  background-color: $color-green;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 2;
}
.sweet-alert .icon.success .line.tip {
  width: 25px;
  left: 14px;
  top: 46px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.sweet-alert .icon.success .line.long {
  width: 47px;
  right: 8px;
  top: 38px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.sweet-alert .sweet-image {
  margin: 20px auto;
}

@-webkit-keyframes showSweetAlert {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
  45% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  80% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes showSweetAlert {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
  45% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  80% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes hideSweetAlert {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}

@keyframes hideSweetAlert {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}

.show-sweet-alert {
  -webkit-animation: showSweetAlert 0.3s;
  animation: showSweetAlert 0.3s;
}
.show-sweet-alert.no-animation {
  -webkit-animation: none;
  animation: none;
}

.hide-sweet-alert {
  -webkit-animation: hideSweetAlert 0.2s;
  animation: hideSweetAlert 0.2s;
}
.hide-sweet-alert.no-animation {
  -webkit-animation: none;
  animation: none;
}

@-webkit-keyframes animate-success-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes animate-success-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@-webkit-keyframes animate-success-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@keyframes animate-success-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@-webkit-keyframes rotatePlaceholder {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  5% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  12% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
  100% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}

@keyframes rotatePlaceholder {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  5% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  12% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
  100% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}

.animate-success-tip {
  -webkit-animation: animate-success-tip 0.75s;
  animation: animate-success-tip 0.75s;
}

.animate-success-long {
  -webkit-animation: animate-success-long 0.75s;
  animation: animate-success-long 0.75s;
}

.icon.success.animate::after {
  -webkit-animation: rotatePlaceholder 4.25s ease-in;
  animation: rotatePlaceholder 4.25s ease-in;
}

@-webkit-keyframes animate-error-icon {
  0% {
    -webkit-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1;
  }
}

@keyframes animate-error-icon {
  0% {
    -webkit-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1;
  }
}

.animate-error-icon {
  -webkit-animation: animate-error-icon 0.5s;
  animation: animate-error-icon 0.5s;
}

@-webkit-keyframes animate-x-mark {
  0% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }
  80% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
    margin-top: -6px;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes animate-x-mark {
  0% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }
  80% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
    margin-top: -6px;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    margin-top: 0;
    opacity: 1;
  }
}

.animate-x-mark {
  -webkit-animation: animate-x-mark 0.5s;
  animation: animate-x-mark 0.5s;
}

@-webkit-keyframes pulse-warning {
  0% {
    border-color: #f8d486;
  }
  100% {
    border-color: #f8bb86;
  }
}

@keyframes pulse-warning {
  0% {
    border-color: #f8d486;
  }
  100% {
    border-color: #f8bb86;
  }
}

.pulse-warning {
  -webkit-animation: pulse-warning 0.75s infinite alternate;
  animation: pulse-warning 0.75s infinite alternate;
}

@-webkit-keyframes pulse-warning-ins {
  0% {
    background-color: #f8d486;
  }
  100% {
    background-color: #f8bb86;
  }
}

@keyframes pulse-warning-ins {
  0% {
    background-color: #f8d486;
  }
  100% {
    background-color: #f8bb86;
  }
}

.pulse-warning-ins {
  -webkit-animation: pulse-warning-ins 0.75s infinite alternate;
  animation: pulse-warning-ins 0.75s infinite alternate;
}

@-webkit-keyframes rotate-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
