a{
    cursor:pointer;
}

.spanNomeUtente{
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;
}

.btn.btn-fill{
    background-color: #f5f5f5 !important;
    border-color:#e5e5e5 !important;
    color:#7d7d7d;
}

.btn.btn-fill:focus{
    color:#7d7d7d !important;
}

.lock-page .user-profile{
    width:300px
}

.btn-margin-left{
    margin-left: 10px;
}

.logo-auth{
    display: inline;    
}

.logo-auth img{
    width: 30px;    
}

.card label{
    font-weight: 400;
    color: #9A9A9A;
}

.card .dx-field-item-help-text, .dx-field-item-label-text{
    color: #9A9A9A;
}

.login-page .card .dx-item{
    margin-top:15px;
}

.login-page .card .title{
    font-size:28px;
    font-weight: 400;
}

.recuperaPassword .dx-field-item-label-text, .recuperaPassword span{ 
    color:white;
    font-weight: normal;
}

.dx-button-mode-contained,.dx-button-mode-contained.dx-state-focused, .dx-button-mode-contained:hover{
    background-color: inherit;
    color: inherit;
}

.btn-neutral:active, .btn-neutral.active, .open > .btn-neutral.dropdown-toggle {
    background-color: inherit;
    color: inherit;
}

.form-avatar {
    height: 128px;
    width: 128px;
    margin-right: 10px;
    border: 1px solid #d2d3d5;
    border-radius: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.btn-info {
    border: 1px solid #23CCEF;
    color: #FFFFFF;
    background-color: #23CCEF;
    cursor: pointer;
}

.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .open>.btn-info.dropdown-toggle {
    background-color: #11bfe3;
    color: #FFFFFF;
    border-color: #11bfe3;
}

.btn-primary {
    border: 1px solid #447DF7;
    color: #FFFFFF;
    background-color: #447DF7;
    cursor: pointer;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.btn-primary.dropdown-toggle {
    background-color: #2c6cf6;
    color: #FFFFFF;
    border-color: #2c6cf6;
}

.btn-success {
    border: 1px solid #87CB16;
    color: #FFFFFF;
    background-color: #87CB16;
    cursor: pointer;
}

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .open>.btn-success.dropdown-toggle {
    background-color: #78b414;
    color: #FFFFFF;
    border-color: #78b414;
}

.btn-warning {
    border: 1px solid #FFA534;
    color: #FFFFFF;
    background-color: #FFA534;
    cursor: pointer;
}

.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .open>.btn-warning.dropdown-toggle {
    background-color: #ff9510;
    color: #FFFFFF;
    border-color: #ff9510;
}

.btnTopGrid{
    display: block;
    text-align: right;
    margin-bottom: 15px;
}

.dxGrid .dx-widget
{
    max-width: 100% !important;
}   

.dx-fileuploader-wrapper{
    padding-left:0px;
}

.dx-fileuploader-input-wrapper{
    border:0px;
}

.btn-success {
    background-color: #8a5c42 !important;
    border-color: transparent !important;
    color:white !important;
    cursor: pointer !important;
}

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .open>.btn-success.dropdown-toggle {
    background-color: #c38f72 !important;
    color:white !important;
}



.timeline {
    position: relative;
    margin: 0 0 30px 0;
    padding: 0;
    list-style: none;
    font-size: 13px;
}

.timeline:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 4px;
    background: #ddd;
    left: 31px;
    margin: 0;
    border-radius: 2px
}

.timeline>li {
    position: relative;
    margin-right: 10px;
    margin-bottom: 15px
}

.timeline>li:before,
.timeline>li:after {
    content: " ";
    display: table
}

.timeline>li:after {
    clear: both
}

.timeline>.time-label>span {
    font-weight: 500;
    padding: 5px;
    display: inline-block;
    background-color: #fff;
    border-radius: 4px;
}

.timeline>li>.timeline-item {
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    margin-top: 0;
    background: #fff;
    color: #444;
    margin-left: 60px;
    margin-right: 15px;
    padding: 0;
    position: relative
}

.timeline>li>.timeline-item>.time {
    color: #999;
    float: right;
    padding: 10px;
    font-size: 12px
}

.timeline>li>.timeline-item>.timeline-header {
    margin: 0;
    color: #555;
    border-bottom: 1px solid #f4f4f4;
    padding: 10px;
    font-size: 15px;
    line-height: 1.1
}

.timeline>li>.timeline-item>.timeline-header>a {
    font-weight: 600
}

.timeline>li>.timeline-item>.timeline-body,
.timeline>li>.timeline-item>.timeline-footer {
    padding: 10px;
    font-size: 13px;
}

.timeline>li>.fa,
.timeline>li>.glyphicon,
.timeline>li>.ion {
    width: 30px;
    height: 30px;
    font-size: 12px;
    line-height: 30px;
    position: absolute;
    color: #666;
    background: #d2d6de;
    border-radius: 50%;
    text-align: center;
    left: 18px;
    top: 0
}

.timeline>.time-label>span {
    font-weight: 600;
    padding: 5px;
    display: inline-block;
    font-size:12px;
    background-color: #fff;
    /*border-radius: 4px*/
}

.timeline .firstClock{
    margin-top: -20px;
}

.timeline-inverse>li>.timeline-item {
    background: #f0f0f0;
    border: 1px solid #ddd;
    -webkit-box-shadow: none;
    box-shadow: none
}

.timeline-inverse>li>.timeline-item>.timeline-header {
    border-bottom-color: #ddd
}

.history{
    background: #F9F9F9;
    padding-top: 30px;
    padding-bottom: 10px;
}

.timeline .consuntivazione{
    cursor:pointer;
    color:#eda600;
    border-top: 1px solid #f4f4f4;
}

.timeline .description{
    white-space: pre-line
}

.timeline-item .div-file{
    margin:10px;
}


.timeline-item .div-file a{
    color: black;
}


.bg-red, .bg-yellow, .bg-aqua, .bg-blue, .bg-light-blue, .bg-green, .bg-navy, .bg-teal, .bg-olive, .bg-lime, .bg-orange, .bg-fuchsia, .bg-purple, .bg-maroon, .bg-black, .bg-red-active, .bg-yellow-active, .bg-aqua-active, .bg-blue-active, .bg-light-blue-active, .bg-green-active, .bg-navy-active, .bg-teal-active, .bg-olive-active, .bg-lime-active, .bg-orange-active, .bg-fuchsia-active, .bg-purple-active, .bg-maroon-active, .bg-black-active, .callout.callout-danger, .callout.callout-warning, .callout.callout-info, .callout.callout-success, .alert-success, .alert-danger, .alert-error, .alert-warning, .alert-info, .label-danger, .label-info, .label-warning, .label-primary, .label-success, .modal-primary .modal-body, .modal-primary .modal-header, .modal-primary .modal-footer, .modal-warning .modal-body, .modal-warning .modal-header, .modal-warning .modal-footer, .modal-info .modal-body, .modal-info .modal-header, .modal-info .modal-footer, .modal-success .modal-body, .modal-success .modal-header, .modal-success .modal-footer, .modal-danger .modal-body, .modal-danger .modal-header, .modal-danger .modal-footer {
    color: #fff !important;
}


.bg-green, .callout.callout-success, .alert-success, .label-success, .modal-success .modal-body {
    background-color: #1bb99a !important;
}

.bg-yellow, .callout.callout-warning, .alert-warning, .label-warning, .modal-warning .modal-body {
    background-color: #f1b53d !important;
}

.bg-red, .callout.callout-danger, .alert-danger, .alert-error, .label-danger, .modal-danger .modal-body {
    background-color: #fb7e6d !important;
}

.bg-blue{
    background-color:#2C82C9 !important;
}

.bg-purple {
    background-color:#605ca8 !important;
}

.bg-aqua {
    background-color:#00c0ef !important;
}

.bg-orange {
    background-color:#FF851B !important;
}

.bg-gray {
    background-color: #d2d6de !important;
}

.bg-maroon {
    background-color: #D81B60 !important;
}

.purple{
    color:#605ca8 !important;
}

.orange{
    color:#FF851B !important;
}

.yellow{
    color:#f1b53d !important;    
}


.listaChiavi h6 {
    font-size: 1em;
    line-height: 1em;
    padding: 0;
    margin: 0;        
    margin-bottom: 10px;
}

.listaChiavi .divRichiesta {
    clear: both;
    width: 100%;
}

.listaChiavi .divRichiestaCorpo {       
    background-color: #fff;
    border: 1px solid #eeeeee;
    padding: 10px;
    margin: 5px 0px 15px 10px;
}

.listaChiavi .lblprodottoId {
    float: left;
    width: 50%;
}

.listaChiavi .divRichiestaDate {
    float: right;
    width: 40%;
    text-align: right;
}

.listaChiavi .lblDescrizione {
    clear: both;
    padding-top: 10px;
    padding-bottom: 20px;
}

.listaChiavi .rilasciata{
    color:green;
    font-weight:bold;
}
.listaChiavi .nonRilasciata{
    color:orange;
    font-weight:bold;
}

.myCard{
    -position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #f0f1f2;
    border-radius: .1875rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 2px 3px rgba(56,59,61,.05);
}

.myCard .card-header{
    border-radius: calc(.1875rem - 1px) calc(.1875rem - 1px) 0 0;
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    color: inherit;
    background-color: #fff;
    border-bottom: 1px solid #f0f1f2;
}

.myCard .card-body{
    flex: 1 1 auto;
    padding: 1.25rem;
}

.myCard .card-footer:last-child {
    border-radius: 0 0 calc(.1875rem - 1px) calc(.1875rem - 1px);
}

.myCard .card-footer {
    padding: .75rem 1.25rem;
    background-color: #fff;
    border-top: 1px solid #f0f1f2;
}

.media {
    display: flex;
    align-items: flex-start;
    margin-top:0px;
}

.align-items-center {
    align-items: center!important;
}

.myCard .media-left {
    padding-right: .625rem;
}

.media-left {
    padding-right: 1.25rem;
}

.rounded-circle {
    border-radius: 50%!important;
}

.media-body {
    flex: 1;
}

.myCard .card-title {
    margin-bottom: 0px;
    margin-top: 0px;
    
    font-weight: 400;
    line-height: 1.5;
    color: rgba(57,68,77,.84);
}

.card-subtitle {
    color: #b8bdc2;
    margin-top: -.5rem;
    font-size: 10px;
    text-transform: uppercase;    
    font-weight: 500;
}

.myCard .media-right {
    padding-left: .625rem;
}

.btn-secondary, .btn-secondary:hover, .btn-secondary:active, .dx-fileuploader-button {
    color: #fff !important;
    background-color: #6b757d !important;
    border-color: #6b757d !important;
    box-shadow: inset 0 1px 0 hsla(0,0%,100%,.15), 0 1px 1px rgba(0,0,0,.075) !important;
    padding: 5px 10px;
    margin-right:10px;
}

.btn-right{
    float:right !important;
}

.btn-group-sm>.btn, .btn-sm {
    padding: .25rem .5rem;
    line-height: 1.5;
    border-radius: .2rem;
}

.dx-fileuploader-button
{
    float:right !important;
    margin-right:0px;
}

.dx-fileuploader-button .dx-button-content{
    padding: 0px;
}


.licenseCode{
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1.5rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    width: 100%;
    background: #c5f3c5;
    margin-top: 15px;
    word-break: break-word;
}


.tableData{
    width:100%;
}


.tableData .row_gv_header {
    font-weight: 500;
    background: #f5f4f4;
    border: 1px solid rgba(221,221,221,.6);
}

.tableData .row_gv_header td {
    padding: 10px;
    text-align: center;
}

.row_gv_modulo:nth-child(even) {
    background-color: #fffdfb;
}

.row_gv_modulo td{
    padding: 10px;
    text-align: center;
}

.row_gv_modulo:nth-child(odd) {
    background-color: #f9f9f9;
}

.sidebar .nav-link{
    padding-top:5px;
    padding-bottom:5px;
}

.sidebar .sidebar-wrapper .nav ul > li > a i{
    font-size:14px;
}

.myCard .lblNonConf_str {
    display: flex;
    float: right;
    color: #bb2525;
}

.myCard .lbldataConf_str {
    display: flex;
    float: right;
    color: green;
}

.myCard .btnFull{
    width:100%;
}

.listino .card-title{
    cursor:pointer;
}

.contenitoreListino {
    margin-bottom: 20px;
    font-family: sans-serif;
}

.contenitoreListino h2 {   
    margin: 0;
    margin-bottom: 10px;
}

.contenitoreListino .contentpretable {
    background-color: #bfbfbf;
    color: #fff;
}

.contenitoreListino .contentpretable th {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}

.contenitoreListino table {
    border: none;
    border-collapse: collapse;
    width: 100%;
}

.contenitoreListino tr.secondheader {
    text-align: center;
    border: 1px solid #d4d4d4;
}

.contenitoreListino tr.secondheader th {
    background-color: #e6e6e6 !important;
    font-weight: 500;
    text-align: center;
}

.contenitoreListino .secondheader td {
    text-align: center;
}

.contenitoreListino table td, .contenitoreListino table th {
    border: none;
}

.contenitoreListino .tcenter  {
    text-align: center;
}

.contenitoreListino .borderLeft {
    border-left: 1px solid !important;
}

.contenitoreListino .borderright {
    border-right: 1px solid !important;
}

.contenitoreListino .bordertop {
    border-top: 1px solid !important;
}

.contenitoreListino .borderbottom {
    border-bottom: 1px solid !important;
}

.contenitoreListino .bordered {
    border: 1px solid #d4d4d4 !important;
}

.contenitoreListino .panel-body tr:nth-child(2n+1) {
    background-color: #f5f5f5;
}

.contenitoreListino .trCosti {
    background-color: white !important;
    border-bottom: 1px solid;
}

.contenitoreListino .trWhite {
    background-color: white !important;
}

.contenitoreListino .panel-title a{
    background-color: #b7c3cd!important;
    color: #fff;
    font-weight: 100;
    border-bottom: 1px solid #bebebe;
    PADDING: 10px;
    background: linear-gradient(135deg,#efaa01 0,#fbfbfb 100%)!important;
}

.contenitoreListino .panel-group{
    margin-top:10px;
    margin-bottom:0px;
}

.contenitoreListino .hidden{
    display: none;
}

.contenitoreListino .rowFooter{
    border: 1px solid; 
    border-left: 0px solid;
}

.search-form input{
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #f0f1f2;
    border-radius: .25rem;
    width:100%;
    margin-bottom:15px;
}

.search-form .form-control, .search-form .form-control:focus {
    border-color: transparent;
    box-shadow: none;
}

.divCards{
    padding-right: 0px;
    padding-left: 0px;
    margin-top:10px;
}

.divCards .group-header{
    color: #eda600;
    color: #fff;
    PADDING: 10px;
    background: linear-gradient(135deg,#eca600 0,#fbfbfb 100%)!important;
}

.myLabel{
    font-weight: normal;
}

.dxGrid .dx-datagrid .dx-column-lines > td
{
    border-right: 0px;
   /* padding-top:13px;
    padding-bottom:13px;*/
    border-left: 0px;    
   /* border-bottom: 1px solid #E3E3E3;*/
}

.dxGrid .dx-datagrid-borders > .dx-datagrid-filter-panel, 
.dxGrid .dx-datagrid-borders > .dx-datagrid-headers, 
.dxGrid .dx-datagrid-borders > .dx-datagrid-pager {
    border:0px;
    border-bottom: 1px solid #E3E3E3;
    border-top: 1px solid #E3E3E3;
}

.dxGrid .dx-datagrid-borders .dx-datagrid-rowsview
{
    border:0px;
}

.dxGrid .dx-datagrid-rowsview tr:nth-of-type(odd) {
    background-color: rgb(245 245 245) !important;
}

.red{
    color:#fb404b;
}

.green{
    color:#87CB16;
}

.orange{
    color:#eda600;
}

.brown {
    color:#8a5c42;
}

.dxGrid .dx-datagrid-headers td div {    
    font-weight: 500;
}

.dx-fileuploader-file-size, .dx-fileuploader-file-status-message{
    display: none;
}

.dx-fileuploader-file-container {
    width: auto;
    padding: 4px 0;
    display: inline;
    margin-right: 10px;
}

.dx-fileuploader-file, .div-file{
    font-size: 11px;
    border: 1px solid #ececec;
    padding: 4px;
    background: #f9f7f7;
    border-radius: 5px;
}

.dx-fileuploader-show-file-list .dx-fileuploader-files-container{
    padding-top:0px;
}

.div-file{
    display: inline-block;
    margin-right: 10px;
    border: 1px solid #d4cfcf;
    margin-bottom: 5px;
}


.parent-block {
    border: 1px solid #f4f4f4;
    padding: 5px;
    margin-bottom: 10px;
    background: #ffff;
}

.parent-block .parent-img {
    max-width: 100px;
    max-height: 100px;
    height: auto;
    float: left;
}

.parent-block .parent-pushed {
    margin-left: 110px;
}

.parent-block .parent-heading{
    margin-top: 5px;
    margin-bottom: 5px;
}

.parent-block .parent-text {
    color: #555;
}

.contentRow{
    border: 1px solid #dad5d5;
    background: whitesmoke;
    min-height: 150px;
    margin-bottom: 15px;
    padding:5px;
    border-radius: 8px;
    margin: 5px;
    background-color: rgba(192, 192, 192, 0.4);
    vertical-align: top;
    white-space: normal;
    display: flex;
}

.contentSection{
    min-height: 135px;
    background: whitesmoke;
    border: 1px solid #dad5d5;
    padding:5px;
    text-align: center;
    border-radius: 5px;
    margin-right: 2px;
}

.contentSection > div{
    height: 100%;
}

.popupSection .contentSection{
    margin-right: 0px;
}

.contentRowHide{
    opacity: 0.5;
}

.right{
    text-align: right;
}

.contentButton span, .contentWidgetType{
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #F0F0F0), color-stop(1, #F9F9F9));
    border: 1px solid #C0C0C0;
    box-shadow: 0 1px 1px rgba(0,0,0,0.04), inset 0 1px 0 #FFF;    
    border-radius: 2px;
    margin-left: 5px;
    color: grey;
    box-sizing: border-box;
    height: 22px;
    padding: 3px;
    margin-top: 15px;
    font-size: 13px !important;
    color: grey;
    cursor:pointer;
}

.contentButton span:hover{
    background: white;    
}

.contentButton{
    padding-top:10px;
}

.contentWidgetButton{    
    position: absolute;
    top:3px;
    right: 10px;
    font-size:10px;
    opacity: 0;
    cursor: pointer;
    background: white;
    z-index: 20;
    height: 150px;
    width: 150px;
    text-align: right;
}


.contentWidgetButton:hover{    
    opacity:1;
}

.contentWidgetButton span{
    margin-left: 5px;    
}

.contentWidget{
    margin-bottom: 5px;
    max-height: 49px;
    -ms-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0 1px 2px rgba(0,0,0,0.075);
    -moz-box-shadow: 0 1px 2px rgba(0,0,0,0.075);
    box-shadow: 0 1px 2px rgba(0,0,0,0.075);
    border: 1px solid #ddd;
    background: white;
    padding-top: 6px;
    border-radius: 5px;
    padding-bottom: 6px;
    position: relative;
    text-align: center;
    padding-left: 24px;
    overflow: hidden;
    cursor:pointer;
}

.contentSectionSelect{
    border: 1px #a17c67;
    border-style: dashed;
}

.no-padding{
    padding:0px !important;
}

.no-margin-rl{
    margin-right:0px !important;
    margin-left:0px !important;
}

.contentWidgetType{
    position: absolute;
    top: 5px;
    left: 0px;
    height: auto;
    margin-top: 0px;
    padding: 1px;
}

.btnContent{
    align-items: center;
    border-radius: 4px;
    background-color: rgb(34, 138, 230);
    border-color: rgb(34, 138, 230);    
    border:0px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    cursor: pointer;
    display: inline-block;
    font-family: Roboto, sans-serif;
    font-size: 15.6px;
    font-weight: 400;
    justify-content: center;   
    line-height: 50px;
    margin-bottom: 16px;
    margin-left: 0px;
    margin-right: 3px;
    margin-top: 0px;
    width: 100%;
    outline-color: rgb(255, 255, 255);
    outline-style: none;
    outline-width: 0px;    
    padding-bottom: 2px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 2px;
    text-align: center;       
}

.btnContent:hover, .btnContent:focus, .btnContent:active, .btnContent:active:focus, .btnContent:active:hover, .btnContent.active:focus, .btnContent.active:hover {
    background-color: #50a2eb;
    color: rgb(255, 255, 255);

}

.linkContent{
    box-sizing: border-box;
    display: inline-block;
    font-size: 35.8px;
    font-weight: 400;
    line-height: 48px;
    margin-bottom: 16px;
    outline-style: none;
    outline-width: 0px;
    font-family: Roboto, sans-serif;
    overflow-wrap: break-word;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    text-align: center;
    text-decoration-line: none;
    text-decoration-style: solid;
    text-decoration-thickness: auto;
    text-rendering: optimizelegibility;
    cursor: pointer;
    color: rgb(84, 97, 122);
    width: 100%;
}

.linkContent:hover{
    color: rgb(80, 162, 235);
    text-decoration: rgb(80, 162, 235);
    text-decoration-color: rgb(80, 162, 235);
}

.mdc-card{
    /*box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
    background-color: #fff;
    border-radius: 2px;*/
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 30px;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 5%), 0 0 0 1px rgb(63 63 68 / 10%);
    background-color: #ffffff; 
}

.mdc-card .body{
    padding: 2rem;    
}

.mdc-card .title, .textContent h2, .linkListContent h2{
    color: rgb(84, 97, 122);
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 10px;

    /*
    color: #333333;
    font-weight: 300;*/
    outline-width: 0px;   
    text-align: center;
}

.mdc-card .title{
    margin-top: 0px;
}

.mdc-card .text, .textContent p, .linkListContent p{
    color: #9a9a9a;
    font-weight: 300;
    line-height: 1.5;
    text-align: center;
}

.linkListContent .linkContent, .linkListContent p{
    text-align: left;
}

.linkListContent .linkContent{
    font-size:25px;
    margin-bottom: 5px;
    line-height: 30px;
}

.mdc-card .btnContent
{
    width: auto;
}

.mdc-card .video{
    margin-bottom: 20px;
}

.mdc-card .button{
    text-align: center;
}

.imageContent{
    background-color: rgb(255, 255, 255);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
    box-sizing: border-box;
    color: rgb(141, 153, 174);
    display: block;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    outline-color: rgb(141, 153, 174);
    outline-style: none;
    outline-width: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    text-align: left;
}

.imageContent img, .mdc-card img{
    width: 100%;
}


.widgetContent{
    padding-top:10px;
    padding-bottom: 10px;
}

.linkListContent .col-image{
    display: inline;
    position: relative;
    min-height: 1px;
    /*padding-right: 15px;
    padding-left: 15px;*/
}

.linkListContent{
    display: flex;
    border-radius: 4px;
    padding: 5px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 5%), 0 0 0 1px rgb(63 63 68 / 10%);
    background-color: #ffffff;    
}

.linkListContent .col-text{
    display: inline;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    width: calc(100% - 200px);
}

.linkListContent .linkContent{
    color:#eda600;    
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 400;    
}

.linkListContent h4{
    margin-top:15px;
    margin-bottom: 5px;
    color: #333333;
    font-weight: 300;
}

.contentPageTitle{
    margin-top: 0px;
    padding-left: 28px;
    font-size: 35px;
    font-family: Roboto, sans-serif;
}

.navbar-brand a{
    cursor: pointer;
}

.align-center{
    text-align: center;
}

.eventPointerBold{
    font-weight: bold;
}

.eventPointer{
    width:10px;
    height:10px;
    background:#eda600;
    position:absolute;
    border-radius:10px;
    margin-left:3px
}

.listEvents span {
    color: #eda600;
    text-transform: uppercase;
    font-weight: 700;
}

.listEvents a {
    color: grey;
    font-size: 13px !important;
}

.calendar .dx-calendar{
    width: 100%;
    min-width: 200px;
}

.listEvents a{
    cursor: pointer;
}

/*TIMELINE*/


.timeline__page .section_title__body {
    margin-bottom: 40px;
}


.timeline__page a{
    cursor: pointer;
}

@media (max-width: 767px) {
    .timeline__page .section_title__body {
        margin-bottom: 10px;
    }
}

.timeline__page .section_title__body .section__title {
    z-index: 99;
    display: inline-block;
    position: relative;
}

.timeline__page .section_title__body .section__title:before {
    position: absolute;
    content: "";
    left: 0;
    width: 60%;
    bottom: 0;
    border-top: 42px solid #eda600;
    z-index: -1;
}

@media (max-width: 991px) {
    .timeline__page .section_title__body .section__title:before {
        border-top: 34px solid #eda600;
    }
}

@media (max-width: 767px) {
    .timeline__page .section_title__body .section__title:before {
        border-top: 20px solid #eda600;
    }
}

.timeline__page .section_title__body > .section_title__desc {
    padding-right: 250px;
}

@media (max-width: 991px) {
    .timeline__page .section_title__body > .section_title__desc {
        padding-right: 100px;
    }
}

@media (max-width: 767px) {
    .timeline__page .section_title__body > .section_title__desc {
        padding-right: 0;
        font-size: 12px;
    }
}

.timeline__page .section__subtitle {
    letter-spacing: 0.6em;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
}

@media (max-width: 767px) {
    .timeline__page .section__subtitle {
        font-size: 11px;
        letter-spacing: 0.3em;
    }
}

.timeline__page .section__subtitle > span {
    color: #eda600;
}

.timeline__page .section__title {
    font-size: 68px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
}

@media (max-width: 991px) {
    .timeline__page .section__title {
        font-size: 56px;
    }
}

@media (max-width: 767px) {
    .timeline__page .section__title {
        font-size: 32px;
        letter-spacing: 0.1em;
    }
}

.timeline__page .dark__title {
    color: #2d4052;
}

.timeline__page .light__subtitle {
    color: #ffffff;
}

.timeline__page .light__title {
    color: #ffffff;
}

.timeline__page .row_centered {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 80px;
}

.timeline__page .section__home {
    height: 40vh;
    width: 100%;
    padding: 0;
    position: relative;
}

.timeline__page .section__home:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.timeline__page .section__home > .container {
    height: 100%;
}

.timeline__page .section__home > .container > .row {
    display: table;
    width: 100%;
    height: 100%;
    margin: 0;
}

.timeline__page .section__home > .container > .row [class*="col-"] {
    display: table-cell;
    float: none;
    vertical-align: middle;
}

.timeline__page .section__home .container.home__body {
    padding-top: 70px;
    height: 100%;
}

.timeline__page .section__home .container.home__body .home__content {
    color: #ffffff;
}

.timeline__page .section__home .container.home__body .home__content .home__heading {
    font-size: 62px;
    letter-spacing: 0.2em;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
}

@media (max-width: 767px) {
    .timeline__page .section__home .container.home__body .home__content .home__heading {
        font-size: 42px;
        letter-spacing: 0.1em;
    }
}

.timeline__page .section__home .container.home__body .home__content .home__heading > span {
    color: #eda600;
}

.timeline__page .section__home .container.home__body .home__content .home__info {
    margin-bottom: 40px;
}

@media (max-width: 767px) {
    .timeline__page .section__home .container.home__body .home__content .home__info {
        font-size: 12px;
        margin-bottom: 20px;
    }
}

.timeline__page .section__home .home__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /*background: url(../img/events_img.jpg) no-repeat center center / cover;*/
    z-index: -999;
}

.timeline__page .section__categories {
    padding: 60px 0;
    border-bottom: 1px solid rgba(169, 169, 169, 0.4);
}

.timeline__page .section__categories .category__item {
    padding: 40px 20px;
    text-align: center;
    -webkit-transition: all .3s;
    transition: all .3s;
    border-bottom: 5px solid transparent;
}

.timeline__page .section__categories .category__item .category_item__icon {
    font-size: 56px;
    color: #2d4052;
    position: relative;
}

.timeline__page .section__categories .category__item .category_item__icon:before {
    position: absolute;
    content: "";
    top: 5px;
    left: 31%;
    height: 55px;
    width: 55px;
    z-index: -1;
    border-radius: 50%;
    background-color: #eda600;
    opacity: 0.8;
}

@media (max-width: 991px) {
    .timeline__page .section__categories .category__item .category_item__icon:before {
        left: 36%;
    }
}

.timeline__page .section__categories .category__item .category_item__title {
    text-decoration: none;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 16px;
    color: #2d4052;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
}

.timeline__page .section__categories .category__item .category_item__title:hover,
.timeline__page .section__categories .category__item .category_item__title:active,
.timeline__page .section__categories .category__item .category_item__title:focus {
    color:default;
}

.timeline__page .section__categories .category__item .category_item__desc {
    margin-top: 10px;
    font-style: italic;
}

.timeline__page .section__categories .category__item:hover,
.timeline__page .section__categories .category__item:active,
.timeline__page .section__categories .category__item:focus {
    box-shadow: 0 10px 25px 10px rgba(199, 199, 199, 0.3);
    border-bottom: 5px solid #eda600;
}

.timeline__page .section__timeline {
    padding: 20px 0;
}

.timeline__page .section__timeline .timeline {
    list-style: none;
    padding: 20px 0 20px;
    position: relative;
}

.timeline__page .section__timeline .timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: "";
    width: 1px;
    background-color: rgba(169, 169, 169, 0.4);
    left: 50%;
    margin-left: -1.5px;
}

.timeline__page .section__timeline .timeline > li {
    margin-bottom: 20px;
    position: relative;
}

.timeline__page .section__timeline .timeline > li:before {
    content: " ";
    display: table;
}

.timeline__page .section__timeline .timeline > li:after {
    content: " ";
    display: table;
    clear: both;
}

.timeline__page .section__timeline .timeline > li > .timeline-badge {
    width: 50px;
    height: 50px;
    line-height: 52px;
    font-size: 26px;
    text-align: center;
    position: absolute;
    top: 16px;
    left: 50%;
    margin-left: -25px;
    z-index: 100;
    border-radius: 50%;
    padding-top: 10px;
}

.timeline__page .section__timeline .timeline > li > .timeline-badge > i {
    color: #ffffff;
}

.timeline__page .section__timeline .timeline > li > .timeline-badge.primary {
    background-color: #eda600;
}

.timeline__page .section__timeline .timeline > li > .timeline-badge.accent {
    background-color: #2d4052;
}

.timeline__page .section__timeline .timeline > li > .timeline-badge.info {
    background-color: #5a8bac;
}

.timeline__page .section__timeline .timeline > li > .timeline-badge.text {
    background-color: #6f6f6f;
}

.timeline__page .section__timeline .timeline > li > .timeline-panel {
    width: 46%;
    float: left;
    border: 1px solid #d4d4d4;
    border-radius: 2px;
    padding: 25px;
    position: relative;
    background: white;
    box-shadow: 0 1px 20px rgba(199, 199, 199, 0.3);
}

.timeline__page .section__timeline .timeline > li > .timeline-panel .timeline-heading .timeline-title {
    text-decoration: none;
    color: #2d4052;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 25px;
    margin-top: 0;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
}

.timeline__page .section__timeline .timeline > li > .timeline-panel .timeline-heading .timeline-title:hover {
    color: #eda600;
}

.timeline__page .section__timeline .timeline > li > .timeline-panel .timeline-info {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    border-bottom: 1px dashed rgba(169, 169, 169, 0.4);
}

.timeline__page .section__timeline .timeline > li > .timeline-panel .timeline-info .timeline-info__item {
    text-align: center;
}

.timeline__page .section__timeline .timeline > li > .timeline-panel .timeline-info .timeline-info__item > i {
    color: #eda600;
    font-size: 20px;
}

.timeline__page .section__timeline .timeline > li > .timeline-panel .timeline-info .timeline-info__item > p {
    font-size: 12px;
    color: #a9a9a9;
}

.timeline__page .section__timeline .timeline > li > .timeline-panel .timeline-body {
    margin: 10px 0;
}

.timeline__page .section__timeline .timeline > li > .timeline-panel .timeline-price {
    margin-bottom: 20px;
    border-bottom: 1px dashed rgba(169, 169, 169, 0.4);
}

.timeline__page .section__timeline .timeline > li > .timeline-panel .timeline-price > h3 {
    font-size: 14px;
    font-weight: 700;
    color: #2d4052;
    margin-top: 10px;
}

.timeline__page .section__timeline .timeline > li > .timeline-panel .timeline-price > h3 > span {
    color: #eda600;
    text-transform: uppercase;
    font-weight: 700;
    margin-left: 10px;
}

.timeline__page .section__timeline .timeline > li > .timeline-panel .timeline-link {
    display: inline-block;
}

.timeline__page .section__timeline .timeline > li > .timeline-panel .timeline-link > a {
    text-decoration: none;
}

.timeline__page .section__timeline .timeline > li > .timeline-panel .timeline-link > a > i {
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
    color: transparent;
}

.timeline__page .section__timeline .timeline > li > .timeline-panel .timeline-link > a:hover > i {
    color: #eda600;
}

.timeline__page .section__timeline .timeline > li > .timeline-panel .timeline-share {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-block;
    float: right;
}

.timeline__page .section__timeline .timeline > li > .timeline-panel .timeline-share > li {
    display: inline-block;
    color: #a9a9a9;
    letter-spacing: 0.02em;
    margin-left: 20px;
}

.timeline__page .section__timeline .timeline > li > .timeline-panel .timeline-share > li > a {
    color: #a9a9a9;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
}

.timeline__page .section__timeline .timeline > li > .timeline-panel .timeline-share > li > a:hover {
    color: #eda600;
}

.timeline__page .section__timeline .timeline > li > .timeline-panel:before {
    position: absolute;
    top: 26px;
    right: -15px;
    display: inline-block;
    border-top: 15px solid transparent;
    border-left: 15px solid #ccc;
    border-right: 0 solid #ccc;
    border-bottom: 15px solid transparent;
    content: " ";
}

.timeline__page .section__timeline .timeline > li > .timeline-panel:after {
    position: absolute;
    top: 27px;
    right: -14px;
    display: inline-block;
    border-top: 14px solid transparent;
    border-left: 14px solid #fff;
    border-right: 0 solid #fff;
    border-bottom: 14px solid transparent;
    content: " ";
}

.timeline__page .section__timeline .timeline > li.timeline-inverted > .timeline-panel {
    float: right;
}

.timeline__page .section__timeline .timeline > li.timeline-inverted > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
}

.timeline__page .section__timeline .timeline > li.timeline-inverted > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
}

@media (max-width: 991px) {
    .timeline__page .section__timeline ul.timeline:before {
        left: 40px;
    }

    .timeline__page .section__timeline ul.timeline > li > .timeline-panel {
        width: calc(85%);
        float: right;
    }

    .timeline__page .section__timeline ul.timeline > li > .timeline-panel:before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto;
    }

    .timeline__page .section__timeline ul.timeline > li > .timeline-panel:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto;
    }

    .timeline__page .section__timeline ul.timeline > li > .timeline-panel .timeline-heading .timeline-title {
        font-size: 24px;
    }

    .timeline__page .section__timeline ul.timeline > li > .timeline-badge {
        left: 15px;
        margin-left: 0;
    }
}

@media (max-width: 767px) {

    .timeline__page .section__timeline ul.timeline > li > .timeline-panel .timeline-info .timeline-info__item {
        text-align: left;
    }

    .timeline__page .section__timeline ul.timeline > li > .timeline-panel .timeline-info .timeline-info__item > i {
        display: inline-block;
        font-size: 16px;
    }

    .timeline__page .section__timeline ul.timeline > li > .timeline-panel .timeline-info .timeline-info__item > p {
        display: inline-block;
    }

    .timeline__page .section__timeline ul.timeline > li > .timeline-badge {
        width: 40px;
        height: 40px;
        line-height: 42px;
        font-size: 24px;
        left: -10px;
        margin-left: 0;
    }

    .timeline__page .section__timeline ul.timeline:before {
        left: 10px;
    }

    .timeline__page .section__timeline ul.timeline > li > .timeline-panel {
        width: calc(85%);
        padding: 15px;
        float: right;
    }

    .timeline__page .section__timeline ul.timeline > li > .timeline-panel:before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto;
    }

    .timeline__page .section__timeline ul.timeline > li > .timeline-panel:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto;
    }

    .timeline__page .section__timeline ul.timeline > li > .timeline-panel .timeline-heading .timeline-title {
        font-size: 20px;
    }

    .timeline__page .section__timeline ul.timeline > li > .timeline-panel .timeline-info {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}

/* BY SARA */
.timeline__page .section__timeline ul.timeline > li > .timeline-panel .timeline-info .timeline-info__item {
    text-align: left;
}

.timeline__page .section__timeline ul.timeline > li > .timeline-panel .timeline-info .timeline-info__item > i {
    display: inline-block;
    font-size: 16px;
}

.timeline__page .section__timeline ul.timeline > li > .timeline-panel .timeline-info .timeline-info__item > p {
    display: inline-block;
    margin-left: 10px;
}

.timeline__page .section__timeline ul.timeline > li > .timeline-badge {
    width: 40px;
    height: 40px;
    line-height: 24px;
    font-size: 24px;
    left: -10px;
    margin-left: 0;
}

.timeline__page .section__timeline ul.timeline:before {
    left: 10px;
}

.timeline__page .section__timeline ul.timeline > li > .timeline-panel {
    width: calc(93%);
    padding: 15px;
    float: right;
}

.timeline__page .section__timeline ul.timeline > li > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
}

.timeline__page .section__timeline ul.timeline > li > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
}

.timeline__page .section__timeline ul.timeline > li > .timeline-panel .timeline-heading .timeline-title {
    font-size: 20px;
}

.timeline__page .section__timeline .timeline > li {
    margin-bottom: 20px;
    position: relative;
}


#event__timer {
    text-align: center;
    font-weight: 700;
    background-color: white;
}

#event__timer > span {
    font-size: 64px;
    color: #2d4052;
}

.h2{
    margin-top:0px;
}

.list-group {
    padding-left: 0;
    margin-bottom: 0px;
}

.list-group-item:first-child, .list-group-item:last-child{
    border-radius: 0px;
}

.card-form{
    border-bottom: 1px solid grey;
}

.align-left{
    text-align: left !important;
}

.card img{
	max-width:100%;
    margin-bottom: 10px;
}


.card-product {
    border-left: 3px solid #ffa726!important;
    cursor:pointer;
    padding: 5px;
    border-radius: 0px;
}


.card-product h4{
    margin:0px;    
}

#products .selected{
    background-color: #fbecda !important;
}

.text-body {
    color: #383b3d!important;
    cursor: pointer;
}

.text-muted {
    color: #b8bdc2!important;
}

.ml-auto, .mx-auto {
    margin-left: auto !important;
}

.d-flex {
    display: flex!important;
}

.installer{
    margin-bottom: 0px;
    color: #725027;
}

.forum-thread-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fbecda;
    border: 1px solid #fbecda;
}


.list-group-item {
    padding-bottom: 20px;
    padding-top: 20px;
}

.list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.list-group-fit .list-group-item {
    border-bottom: 1px solid #f0f1f2;
    border-top:0px;
    border-right: 0px;
    border-left: 0px;
}


.sidebar-mini .sidebar .sidebar-wrapper .group-mini{
    display: inline;
}

.sidebar .sidebar-wrapper .group-mini, .sidebar-mini .sidebar:hover .sidebar-wrapper .group-mini{
    display: none;
}

.sidebar-mini .sidebar .sidebar-wrapper .group-max{
    display: none;
}

.sidebar .sidebar-wrapper .group-max, .sidebar-mini .sidebar:hover .sidebar-wrapper .group-max{
    display: inline;
}

.sidebar-mini .sidebar .sidebar-wrapper > .nav li > a .a-group{    
    opacity: 1;
    transform: none;
}

.sidebar .a-group{
    color:#ffddba !important;
    font-size: 13px !important;
}

.card-stats .icon-big {
    text-align: left;
    margin-left: 15px;
}

.card-stats .icon-big i
{
    font-weight: normal;
}

.journal{
    padding:10px;
    height: 100%;
    background-color: #fffdfa;
}

.journal h2{
    font-size: 17px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
    border-top:1px solid grey;
    border-bottom:1px solid grey;
    font-family: fantasy;
}

.journal p{
    text-align: justify;
    font-size:12px;
    word-break: break-all;
    overflow: auto;
    height: 70%;

}

.journal h1{
    font-size: 25px;
    font-family: fantasy;
    margin-top: 10px;
}

.journal span{
    position: absolute;
    right: 5px;
    font-size: 10px;
}

::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

.dashboardVideo{
    padding:10px;
    height: 100%;
    background-color: #fbfbfb;
}

.dashboardVideo h1{
    font-size: 22px;
    margin-top: 5px;
    font-weight: bold;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
    text-transform: capitalize;
}

.dashboard-card .card{
    height: 445px;
}

.dashboard-card .card .content{
    height: calc(100% - 115px);
    overflow: auto;
}

.card-stats a{
    color:#333;
}

.linkSpan{
    cursor:pointer;
    color: #23ccef;
}

.linkSpanButton{
    cursor: pointer;
    color: white;
}

.listEvents .linkSpan{
    color:grey;
}

.pointer{
    cursor:pointer;
}


.journal .spanRemove, .dashboardVideo .spanRemove{
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    border-radius: 3px;
    color: #fff;
    background-color: #f5564a;
    border-color: #a42e2e;
    position: static;
    padding: 2px 2px;
    font-size: 8px;
}

.dashboardVideo .spanRemove{
    position: absolute;
    right: 5px;
    top: 5px;
}

.navbar-brand span{
    cursor: pointer;
}

.dx-switch-off, .dx-switch-on {
    font-size: 14px;
}

.dx-switch-container {   
    height: 34px;
    padding: 5px;
}

.margin-right{
    margin-right: 15px;    
}